import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Nav = () => {
  return (
    <nav id="navbar-main" aria-label="Primary navigation">
      <Link to="/" alt="Naar de startpagina">
        <StaticImage
          src="../images/fedlogo.jpg"
          alt="Yoshimi Jiu-Jitsu Rengoukai UBU"
          placeholder="dominantColor"
          layout="fixed"
          width={160}
          height={160}
          quality={100}
        />
      </Link>

      <ul>
        <li>
          <Link
            to="/clubs"
            aria-label="menu item"
            activeClassName="nav-item--active"
          >
            Clubs
          </Link>
        </li>
        <li>
          <Link
            to="/activiteiten"
            aria-label="menu item"
            activeClassName="nav-item--active"
          >
            Activiteiten
          </Link>
        </li>
        <li>
          <Link
            to="/documenten"
            aria-label="menu item"
            activeClassName="nav-item--active"
          >
            Documenten
          </Link>
        </li>        
      </ul>
    </nav>
  )
}

export default Nav
